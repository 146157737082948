<template>
  <div
    class="p-2"
    :class="{ 'area-busy': waiting }"
  >
    <FormulateForm
      v-model="formData"
      name="meterForm"
      :class="{ 'view-only': viewOnly }"
      @submit="submitHandler"
    >
      <div class="grid grid-cols-1 gap-4">
        <div>
          <FormulateInput
            name="name"
            type="text"
            label="Name"
            placeholder="Name"
            validation="required"
            validation-behavior="live"
          />

          <FormulateInput
            name="email"
            type="text"
            label="Email"
            placeholder="Email"
            validation="required"
            validation-behavior="live"
          />

          <FormulateInput
            name="password"
            type="password"
            label="Password"
            placeholder="Password"
            :validation="
              formData.mode == 'updateUser' ? 'optional' : 'required'
            "
            validation-behavior="live"
          />

          <div class="formulate-input-custom">
            <label class="formulate-input-label">Roles </label>
            <v-select
              v-model="selectedRoles"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              placeholder="Roles"
              multiple
              :options="userRolesTypeOptions"
            />
          </div>
          <FormulateInput
            name="status"
            type="select"
            :options="userStatusTypeOptions"
            label="Status"
            placeholder="Status"
            validation="required"
            validation-behavior="live"
          />
        </div>
      </div>

      <b-button
        v-if="!viewOnly"
        variant="primary"
        :disabled="waiting"
        class="mr-1"
        block
        @click="$formulate.submit('meterForm')"
      >
        <span v-if="waiting"><b-spinner small /> Saving...</span>
        <span v-else>Save</span>
      </b-button>
    </FormulateForm>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BSpinner,
  BButton,
  BImg,
} from 'bootstrap-vue'
import {
  ref, computed, watch, onMounted,
} from '@vue/composition-api'
import store from '@/store'
import _ from 'lodash'
import vSelect from 'vue-select'
import useSideBar from './useSideBar'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BSpinner,
    BButton,
    BImg,
    vSelect,
  },
  props: {
    formValues: {
      type: Object,
      required: true,
    },
    viewOnly: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  setup(props, { emit }) {
    const formData = ref(props.formValues)
    const vieOnlyProp = ref(props.viewOnly)
    const errorBehavior = ref('blur')

    const selectedRoles = ref(null)

    const userStatusTypeOptions = computed(() => (store.state.app.enums
      ? _.cloneDeep(store.state.app.enums.UserStatusType)
      : []))

    const userRolesTypeOptions = computed(() => (store.state.app.enums
      ? _.cloneDeep(store.state.app.enums.UserRolesType)
      : []))

    const {
      waiting,

      uploadFile,
      submitForm,
    } = useSideBar()

    watch(
      () => vieOnlyProp,
      value => {
        errorBehavior.value = value ? 'blur' : 'live'
      },
    )

    watch(
      formData,
      () => {
        selectedRoles.value = _.filter(
          _.cloneDeep(userRolesTypeOptions.value),
          item => {
            return formData.value.roles.includes(item.value)
          },
        )
      },
      { deep: true },
    )

    onMounted(() => {
      errorBehavior.value = props.viewOnly ? 'blur' : 'live'
    })

    const submitHandler = data => {
      data.roles = _.map(selectedRoles.value, 'value')
      if (data.roles.length == 0) {
        return
      }
      submitForm(data)
        .then(() => {
          emit('success', true)
        })
        .catch(() => {})
    }

    return {
      formData,
      errorBehavior,

      selectedRoles,

      uploadFile,
      submitHandler,

      waiting,

      userStatusTypeOptions,
      userRolesTypeOptions,
    }
  },
}
</script>
