import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDataList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/users/paginate', payload.body, { params: payload.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/users', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios.put(`/users/${payload.id}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
