import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import _ from 'lodash'
import moment from "moment-timezone";

moment.locale("en");
moment.tz("UTC");

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { showErrorToast } from '@leecom/utils/support'

export default function useSideBar() {

    const toast = useToast()
    const sideBarTitle = ref('');
    const sideBarActive = ref(false);
    const waiting = ref(false);

    const placeholderImage = computed(
        () => store.state.appConfig.layout.noImagePlaceholder,
    )

    const openSideBardFor = (type, data = null) => {


        if (type == 'store') {
            sideBarTitle.value = 'Add User';
            formValues.value = {
                mode: 'addUser',
            }
            formViewOnly.value = false;
        }

        if (type == 'update') {
            sideBarTitle.value = 'Edit User';
            const keyedData = _setKeysBeforeLoad(data);
            console.log(keyedData);
            keyedData.mode = 'updateUser';
            formValues.value = keyedData;
            formViewOnly.value = false;
        }

        // if (type == 'view') {
        //     sideBarTitle.value = 'Meter Details';
        //     const keyedData = _setKeysBeforeLoad(data);

        //     keyedData.mode = null;
        //     formValues.value = keyedData;
        //     formViewOnly.value = true;
        // }

        sideBarActive.value = true;
        formKey.value = moment().valueOf();

    }

    const closeSideBar = (event) => {
        sideBarActive.value = event;
        if (!event) {
            formKey.value = null;
        }
    }


    const formKey = ref(null)
    const formValues = ref({});
    const formViewOnly = ref(true);

    const submitForm = (data) => {
        _setBusy(true);

        const keyedData = _setKeysBeforeSubmit(data);

        return new Promise((resolve, reject) => {
            store.dispatch(`app-users/${keyedData.mode}`, keyedData)
                .then((response) => {
                    _setBusy(false);
                    resolve(response);
                })
                .catch((error) => {
                    _setBusy(false);
                    console.log(error);
                    reject(error);
                    const { response } = error;
                    showErrorToast(toast, response, 'Error saving user')
                })
        });
    }

    const uploadFile = (file, progress, error, option) => {
        const formData = new FormData()
        formData.append('image', file)

        return store
            .dispatch('app-meters/uploadImage', formData)
    }








    const _setBusy = (state) => {
        waiting.value = state;
    }

    const _setKeysBeforeSubmit = (data) => {
        const keyedData = _.mapKeys(data, (value, key) => {
            let index = key.replaceAll(' ', '_').toLowerCase();
            if (index == 'name') {
                index = 'full_name';
            }
            return index;
        });

        return keyedData;
    }

    const _setKeysBeforeLoad = (data) => {

        const keyedData = _.mapKeys(data, (value, key) => {
            let index = key;

            if (index == 'full_name') {
                index = 'name';
            }

            index = index.replaceAll('_', ' ');

            // index = _.startCase(index);

            return index;
        });

        keyedData.roles = _.map(keyedData.roles, 'id');

        return keyedData;
    }

    return {
        sideBarTitle,
        sideBarActive,

        openSideBardFor,
        closeSideBar,

        formKey,
        formValues,
        formViewOnly,

        waiting,

        uploadFile,
        submitForm,
    }
}