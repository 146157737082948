import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import _ from 'lodash'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { showErrorToast } from '@leecom/utils/support'

export default function useDataList() {

  const toast = useToast()
  const refDataListTable = ref(null)
  const STORE_MODULE_NAME = 'app-users'
  const addBtnText = 'Add User';

  const tableColumns = [
    { key: 'full_name', sortable: true, filter: true, label: 'Name' },
    { key: 'email', sortable: true, filter: true, },
    { key: 'roles', sortable: false, filter: false, },
    { key: 'status', sortable: true, filter: true, },
    { key: 'actions' },
  ]

  const perPage = ref(10)
  const totalRows = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref(null)
  const isSortDirDesc = ref(false)
  const basicFilter = ref(null)
  const filterInputs = ref({
    full_name: null,
    email: null,
    status: null,
  })

  const dataMeta = computed(() => {
    const localItemsCount = refDataListTable.value ? refDataListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRows.value,
    }
  })

  const refetchData = () => {
    refDataListTable.value.refresh()
  }

  const refetchDataWithDebounce = _.debounce(() => {
    refDataListTable.value.refresh()
  }, 300);

  watch([currentPage, perPage, searchQuery, basicFilter], () => {
    refetchData()
  })

  watch(filterInputs.value, () => {
    refetchDataWithDebounce()
  })

  const resolveSample = role => {
  }

  const resolveRolesLabel = roles => {
    return roles.map(({ name }) => _.startCase(name)).join(", ")
  }

  const resolveUserStatusLabel = status => {
    return _.get(_.find(store.state.app.enums.UserStatusType, { value: status }), 'label', null);
  }

  const resolveUserStatusVariant = status => {
    const { key } = _.find(store.state.app.enums.UserStatusType, { value: status });
    if (key === 'PENDING') return 'warning'
    if (key === 'ACTIVE') return 'success'
    if (key === 'DISABLED') return 'secondary'
    return 'primary'
  }



  const fetchDataList = (ctx, callback) => {
    store
      .dispatch(`${STORE_MODULE_NAME}/fetchDataList`, {
        queryParams: {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          filter: basicFilter.value,
        },
        body: {
          filters: filterInputs.value,
        }
      })
      .then((response) => {

        const { data, total } = response.data.results;

        callback(data)
        totalRows.value = total
      })
      .catch((error) => {
        console.log(error);
        const { response } = error;
        showErrorToast(toast, response)
      })
  }

  return {
    fetchDataList,
    tableColumns,
    perPage,
    currentPage,
    totalRows,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDataListTable,
    filterInputs,

    refetchData,

    resolveSample,
    resolveRolesLabel,
    resolveUserStatusLabel,
    resolveUserStatusVariant,

    basicFilter,

    addBtnText,
  }
}
